<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">订单操作退费</div>
      <div class="formDiv" style="width: 70vw; margin-left: 10vw">
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="订单号:">
                <el-input
                  v-model="FormData.order_id"
                  placeholder="订单编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="设备编号:">
                <el-input
                  v-model="FormData.device_id"
                  placeholder="请输入设备编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="车型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择充电车型"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单时间：" class="time_pick">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <!-- <el-col :span="1" :offset="1">
                <div class="BtnSearch" @click="handleExcel()">导出</div>
              </el-col>
              <el-col :span="1" :offset="1">
                <div class="BtnSearch" @click="handlePrint()">打印</div>
              </el-col> -->
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="订单编号" prop="order_id" width="120" />
          <el-table-column label="手机号" prop="phone" width="110" />
          <el-table-column label="设备编号" prop="device_id" width="130" />
          <el-table-column label="充电车型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column
            label="充电时长(分钟)"
            prop="cost_time"
            width="120"
            :formatter="rounding"
          />
          <el-table-column
            label="开始时间"
            prop="start_time"
            width="160"
            :formatter="formatDate"
          />
          <el-table-column
            label="结束时间"
            prop="end_time"
            width="160"
            :formatter="formatDate"
          />
          <el-table-column
            label="支付金额(元)"
            prop="pay_money"
            :formatter="rounding"
            width="120"
          />
          <el-table-column
            label="支付方式"
            prop="pattern_of_payment"
            width="100"
          />
          <!-- <el-table-column label="电费(元)" prop="pay_money" width="80" :formatter="rounding"/> -->
          <el-table-column
            label="实际收入(元)"
            prop="cost_money"
            :formatter="rounding"
            width="120"
          />
          <el-table-column label="所属运营商" prop="username" width="120" />
          <el-table-column label="操作" align="center" fixed="right">
            <template #default="scope">
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="torefund(scope.$index, scope.row)"
                >退费</el-button
              >

              <!-- <div
                  class="BtnSearch1 BtnSearch"
                  @click="torefund(scope.$index, scope.row)"
                  >退费</div
                > -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <div class="flex1">
            <p>
              临时充电订单数：<span>{{
                tableData1.count_order ? tableData1.count_order : 0
              }}</span>
            </p>
            <p>
              临时充电金额(元)：<span>{{
                tableData1.sum_order ? tableData1.sum_order : "0.00"
              }}</span>
            </p>
          </div>
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
        <div v-dialogdrag>
          <el-dialog
            v-model="Dialog.editLevy"
            title="订单退费"
            width="920px"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv">
              <el-form
                ref="FormRules"
                :rules="rules"
                :model="FormData"
                label-position="right"
                label-width="100px"
              >
                <el-row :gutter="10">
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="订单编号:">
                      <el-input
                        disabled
                        v-model="Dialog.DialogData.order_id"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="支付金额(元):">
                      <el-input
                        disabled
                        v-model="Dialog.DialogData.pay_money"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="1"></el-col>
                  <!-- <el-col :span="3"></el-col> -->
                  <el-col :span="10">
                    <el-form-item label="退费金额(元):">
                      <el-select
                        style="width: 100%"
                        v-model="Dialog.DialogData.money"
                        placeholder="请选择退款金额(元)"
                        @change="handlemoney"
                      >
                        <el-option
                          v-for="moneys in moneyList"
                          :key="moneys.value"
                          :value="moneys.value"
                          :label="moneys.label"
                        />
                        <!-- </el-option> -->
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="10" v-show="isshow">
                    <el-form-item label="退费金额(元):">
                      <el-input
                        type="number"
                        v-model="Dialog.DialogData.refund_fee"
                        placeholder="请输入退费金额(元)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="1"></el-col>
                  <!-- <el-col :span="3"></el-col> -->
                  <el-col :span="10">
                    <el-form-item label="退费原因:">
                      <el-input
                        v-model="Dialog.DialogData.refund_reason"
                        placeholder="请输入退费原因"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog"
                  >确认</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
// import { onMounted } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  order_refund,
  start_chage_yun,
  re_money1,
  operator_data,
} from "@/request/api";
import { ElMessage } from "element-plus";
import ProvinceCityCountry from "../../assets/json/address";
export default {
  name: "UserRecord",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
      },
      isadmin: false,
      users: [],
      refunds: {},
      isshow: false,
      values: "",
      tableData: [],
      tableData1: {},
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      moneyList: [
        {
          value: 0,
          label: "全额退费",
        },
        {
          value: 1,
          label: "手动输入",
        },
      ],
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      car: "",

      loading: false,
      provinceData: ProvinceCityCountry.address,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : "";
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : "";
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = "";
        endDate = "";
      }
      const dataa = {
        username: data.FormData.username,
        operator_id: sessionStorage.getItem("userID"),
        phone: data.FormData.phone,
        type_id: data.FormData.type_id,
        device_id: data.FormData.device_id,
        order_id: data.FormData.order_id,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page: data.currentPage,
      };
      data.loading = true;
      order_refund(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData1 = res;
            data.tableData1.sum_order = Number(res.sum_order)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data.tableData = res.data;
            data.total = res.count_order;
            data.tableData.forEach((a)=>{
              a.pay_money = Number(a.pay_money )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              a.cost_money = Number(a.cost_money )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };

    const openCharge = (index, row) => {
      const dataa = {
        device_id: row.device_id,
        order_id: row.order_id,
      };
      start_chage_yun(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("已开启");
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const torefund = (index, row) => {
      console.log(row);
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
      data.Dialog.DialogData.pay_money
        ? (data.Dialog.DialogData.pay_money =
            data.Dialog.DialogData.pay_money.toFixed(2))
        : (data.Dialog.DialogData.pay_money = "0.00");
      data.refunds.total = row.pay_money;
      data.refunds.out_trade_no = row.order_id;
      data.refunds.transaction_id = row.transaction_id;
      data.refunds.refund_reason = row.refund_reason;
    };
    const handlemoney = (value) => {
      data.values = value;
      if (data.values == 0) {
        data.isshow = false;
        // data.refunds.refund = data.refunds.total
      } else if (data.values == 1) {
        data.isshow = true;
      }
    };

    const submitDialog = () => {
      if (data.values == 0) {
        data.refunds.refund = data.refunds.total;
      } else if (data.values == 1) {
        data.refunds.refund = data.Dialog.DialogData.refund_fee;
      }

      const dataa = data.refunds;
      console.log(dataa, "dataaaaaaaaa");
      re_money1(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("退费成功");
            searchBtn();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("退费失败");
        }
      });
    };
    const handleSizeChange = (val) => {
      console.log(val, "vallllllhandleSizeChange");
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    onMounted(() => {
      getUsername();
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getUsername,
      // getList,
      // handleExcel,
      // handlePrint,
      openCharge,
      handleSizeChange,
      handleCurrentChange,
      formatDate,
      torefund,
      closeDialog,
      submitDialog,
      handlemoney,
      rounding,
    };
  },
};
</script>
  
  <style>
.formDiv {
  width: 75%;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
.bottomDiv {
  height: 100%;
}
.BtnSearch {
  float: left;
  width: 80px;
  margin: 0 4px;
}
.flex1 {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  /* margin-top: -10px; */
}
.flex1 p {
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span {
  color: red;
}
</style>